import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useGetCompanyQuery, useUpdateCompanyMutation } from '../../store/authService';

const FirmScreen = () => {
	const { data, isLoading, isSuccess } = useGetCompanyQuery();
	const [updateCompany, { isLoading: isUpdating, isError, isSuccess: isUpdated }] =
		useUpdateCompanyMutation();

	const [firmInfo, setFirmInfo] = useState({
		address: '',
		phone: '',
		email: '',
		city: '',
		town: '',
		country: '',
		workers_count: '',
		fee: '',
		product_count: '',
		id: '',
		industry_type: '',
	});

	useEffect(() => {
		if (data) {
			setFirmInfo({
				address: data.company.address || '',
				phone: data.company.phone || '',
				email: data.company.email || '',
				city: data.company.city || '',
				town: data.company.town || '',
				country: data.company.country || '',
				workers_count: data.company.workers_count || '',
				fee: data.company.fee || '',
				product_count: data.company.product_count || '',
				id: data.company.id || '',
				industry_type: data.company.industry_type || '',
			});
		}
	}, [data]);

	const updateField = (e) => {
		const { name, value } = e.target;
		setFirmInfo((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		updateCompany(firmInfo);
	};

	return (
		<Row>
			<Col lg={12} xl={12} className="mb-4">
				<Card>
					<Card.Header>Firma Bilgileri</Card.Header>
					<Card.Body>
						{isLoading ? (
							<div className="d-flex align-items-center justify-content-center">
								<Spinner animation="border" role="status" />
							</div>
						) : (
							isSuccess && (
								<Form onSubmit={handleSubmit}>
									<Row>
										<Form.Group as={Col} className="my-3">
											<Form.Label>Firma Adı</Form.Label>
											<Form.Control size="sm" value={firmInfo.name || ''} disabled />
										</Form.Group>
										<Form.Group as={Col} className="my-3">
											<Form.Label>Telefon</Form.Label>
											<Form.Control
												name="phone"
												size="sm"
												value={firmInfo.phone}
												onChange={updateField}
											/>
										</Form.Group>
										<Form.Group as={Col} className="my-3">
											<Form.Label>Adres</Form.Label>
											<Form.Control
												name="address"
												size="sm"
												value={firmInfo.address}
												onChange={updateField}
											/>
										</Form.Group>
									</Row>
									<Row>
										<Form.Group as={Col} className="my-3">
											<Form.Label>E-Posta</Form.Label>
											<Form.Control
												name="email"
												size="sm"
												value={firmInfo.email}
												onChange={updateField}
											/>
										</Form.Group>
										<Form.Group as={Col} className="my-3">
											<Form.Label>İl</Form.Label>
											<Form.Control
												name="city"
												size="sm"
												value={firmInfo.city}
												onChange={updateField}
											/>
										</Form.Group>
										<Form.Group as={Col} className="my-3">
											<Form.Label>İlçe</Form.Label>
											<Form.Control
												name="town"
												size="sm"
												value={firmInfo.town}
												onChange={updateField}
											/>
										</Form.Group>
									</Row>
									<Row>
										<Form.Group as={Col} className="my-3">
											<Form.Label>Çalışan Sayısı</Form.Label>
											<Form.Control
												name="workers_count"
												size="sm"
												value={firmInfo.workers_count}
												onChange={updateField}
											/>
										</Form.Group>
										<Form.Group as={Col} className="my-3">
											<Form.Label>Üretim Kapasitesi</Form.Label>
											<Form.Control
												name="product_count"
												size="sm"
												value={firmInfo.product_count}
												onChange={updateField}
											/>
										</Form.Group>
										<Form.Group as={Col} className="my-3">
											<Form.Label>Endüstri</Form.Label>
											<Form.Control
												name="industry_type"
												size="sm"
												value={firmInfo.industry_type}
												onChange={updateField}
											/>
										</Form.Group>
									</Row>
									<Button variant="success" type="submit" disabled={isUpdating}>
										{isUpdating ? (
											<Spinner animation="border" size="sm" role="status" className="me-2" />
										) : (
											'Güncelle'
										)}
									</Button>
									{isUpdated && (
										<Alert variant="success" className="mt-3">
											Güncelleme başarılı!
										</Alert>
									)}
									{isError && (
										<Alert variant="danger" className="mt-3">
											Güncelleme sırasında bir hata oluştu.
										</Alert>
									)}
								</Form>
							)
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default FirmScreen;
