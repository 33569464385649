// src/components/BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ data }) => {
	// Chart verilerini hazırlama
	const labels = data.map((item) => item.emission_source);
	const scopeOneData = data.map((item) => item.scope_one);
	const scopeTwoData = data.map((item) => item.scope_two);
	const scopeThreeData = data.map((item) => item.scope_three);

	const chartData = {
		labels, // Çubukların altındaki etiketler
		datasets: [
			{
				label: 'Scope One',
				data: scopeOneData,
				backgroundColor: 'rgba(75, 192, 192, 0.5)',
			},
			{
				label: 'Scope Two',
				data: scopeTwoData,
				backgroundColor: 'rgba(54, 162, 235, 0.5)',
			},
			{
				label: 'Scope Three',
				data: scopeThreeData,
				backgroundColor: 'rgba(255, 206, 86, 0.5)',
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top', // Legend konumu
			},
			title: {
				display: true,
				text: 'Emission Sources - Scopes',
			},
		},
	};

	return <Bar data={chartData} options={options} />;
};

export default BarChart;
