// src/components/BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ data }) => {
	console.log('BarChart data:', data);
	// Chart için etiketler ve veri
	const labels = data.map((item) => item.emission_source); // Emission kaynakları
	const carbonFootprints = data.map((item) => item.carbon_footprint); // Karbon ayak izi değerleri

	const chartData = {
		labels, // Etiketler
		datasets: [
			{
				label: 'Carbon Footprint (kg CO2e)', // Grafiğin adı
				data: carbonFootprints, // Veriler
				backgroundColor: 'rgba(75, 192, 192, 0.5)', // Çubukların rengi
				borderColor: 'rgba(75, 192, 192, 1)',
				borderWidth: 1,
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: 'Emission Sources and Carbon Footprint',
			},
		},
	};

	return <Bar data={chartData} options={options} />;
};

export default BarChart;
