import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react';
import { Row, Col, Form, Button, Card, Alert, Spinner } from 'react-bootstrap';
import {
	useGetEmissonResultForAnalysisQuery,
	useGetSourceTypeQuery,
} from '../../store/authService';
import { Bar, Pie } from 'react-chartjs-2';
import { roundNumber } from '../../utils';
import moment from 'moment';
import 'moment/locale/tr';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import BarChartAnalysis from '../../components/Chart/BarChartAnalysis';

moment.locale('tr');
const months = moment.months();

const AnalysisScreen = () => {
	const { data } = useGetSourceTypeQuery();
	const [select, setSelect] = useState([]);
	const [factory, setFactory] = useState(null);
	const [year, setYear] = useState(new Date().getFullYear());
	const { userToken } = useSelector((state) => state.auth);
	const { data: { result, years } = { result: [], years: [] }, isLoading } =
		useGetEmissonResultForAnalysisQuery(
			{ year: year === '' ? undefined : year },
			{ refetchOnMountOrArgChange: true },
		);

	useEffect(() => {
		try {
			setFactory(jwtDecode(userToken).company);
		} catch (error) {
			console.error('JWT çözümlemesinde hata:', error);
			setFactory(null);
		}
	}, [userToken]);

	useEffect(() => {
		console.log('resr', chart);
	}, [result]);

	const handleSelect = useCallback((val) => {
		setSelect((prev) => {
			if (prev.includes(val)) {
				return prev.filter((item) => item !== val);
			}
			return [...prev, val];
		});
	}, []);

	const chart = useMemo(() => {
		let data = [];
		let label = [];
		result
			.filter((item) => select.includes(item.id))
			.forEach((item) => {
				label.push(item.emission_source);
				data.push(item.carbon_footprint);
			});
		console.log(result);
		return { label, data };
	}, [result, select]);

	const Scope = useMemo(
		() => (props) => {
			return (
				data &&
				data.source_type
					.filter((value) => value.scope === parseInt(props.scopeId, 10))
					.map((value) => (
						<Fragment key={value.id}>
							<input
								size="sm"
								type="checkbox"
								className="btn-check d-none me-3"
								id={`scope_button-${value.id}`}
								variant="outline-secondary"
								autoComplete="off"
								checked={select.includes(value.id)}
								disabled={!result?.some((item) => item.id === value.id)}
								onChange={() => handleSelect(value.id)}
							/>
							<label className="btn btn-outline-secondary" htmlFor={`scope_button-${value.id}`}>
								{value.label}
							</label>
						</Fragment>
					))
			);
		},
		[data, select, handleSelect, result],
	);

	return (
		<>
			<Row>
				<Col md={12} className="mb-5">
					<h5>Analizler</h5>
				</Col>
				{isLoading && (
					<Col md={12} className="mb-5 text-center">
						<Spinner animation="border" />
					</Col>
				)}
				<Col md={12} className="mb-5">
					<Form>
						<Row>
							<Col>
								<Form.Select size="sm" value={year} onChange={(e) => setYear(e.target.value)}>
									<option value="">Dönem Seçiniz</option>
									{years.map((item) => (
										<option value={item} key={item.id}>
											{item}
										</option>
									))}
								</Form.Select>
							</Col>
							<Col>
								<Form.Select defaultValue="Lokasyon Seçiniz" size="sm">
									<option>Lokasyon Seçiniz</option>
									{factory && <option>{factory[0].city}</option>}
								</Form.Select>
							</Col>
							<Col className="d-flex justify-content-end">
								<Button variant="success" size="sm">
									Excel'e Aktar
								</Button>
							</Col>
							<Col>
								<Button variant="secondary" size="sm" disabled>
									Rapor Oluştur
								</Button>
							</Col>
						</Row>
					</Form>
				</Col>
				<Col md={12} lg={12} xl={8}>
					<Form>
						<Row>
							{[1, 2, 3].map((scopeId) => (
								<Col key={scopeId} lg={12} xl={12} className="mb-4">
									<Card>
										<Card.Header>
											<Card.Title>Kapsam {scopeId} Emisyonları</Card.Title>
										</Card.Header>
										<Card.Body>
											<div className="btn-group btn-group-sm" role="group">
												<Scope scopeId={scopeId} />
											</div>
										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>
					</Form>
				</Col>
				{/* <Col sm={12} md={12} lg={12} xl={4}>
					{chart.label.length === 0 ? (
						<Alert variant="info">Henüz gösterilecek bir sonuç bulunmuyor.</Alert>
					) : (
						<>
							<h6>Sonuçlar</h6>
							<Pie
								redraw
								data={{
									labels: chart.label,
									datasets: [
										{
											label: 'Toplam Emisyon',
											data: chart.data,
											options: {
												maintainAspectRatio: false,
											},
										},
									],
								}}
							/>
							{chart.label.map((item, index) => (
								<div key={item}>
									{item}- {roundNumber(chart.data[index])} tCO2
								</div>
							))}
						</>
					)}
				</Col> */}
				{chart.label.length !== 0 ? (
					<BarChartAnalysis data={result} />
				) : (
					<Alert variant="info">Henüz gösterilecek bir sonuç bulunmuyor.</Alert>
				)}
			</Row>
		</>
	);
};

export default AnalysisScreen;
